import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function App() {
  const [flashcards, setFlashcards] = useState("");
  const [answered, setAnswered] = useState(false);
  const [position, setPosition] = useState(0);
  const [finished, setFinished] = useState(false);
  const { sessionId } = useParams();

  function answerFlashcard() {
    setAnswered(true);
  }

  function nextFlashcard() {
    setPosition(position + 1);
    setAnswered(false);
    if (position === flashcards.length - 1) {
      setFinished(true);
    }
  }

  function restart() {
    setPosition(0);
    setAnswered(false);
    setFinished(false);
  }

  function getFlashcard() {
    if (flashcards) {
      return flashcards[position];
    } else {
      return "";
    }
  }

  function hideClozes(text) {
    return text.replace(/{.*?}/g, "_____");
  }

  function highlightCloze(text) {
    return text
      .replace(/{.*?}/g, '<span class="font-bold">$&</span>')
      .replace(/[{}]/g, "");
  }

  function getContent() {
    if (finished) {
      return null;
    } else if (answered) {
      return highlightCloze(getFlashcard());
    } else {
      return hideClozes(getFlashcard());
    }
  }

  function getBottom() {
    if (finished) {
      return (
        <button
          className="bg-teal-800 hover:bg-teal-600 duration-700 rounded-lg px-5 py-1.5 text-white"
          onClick={restart}
        >
          Restart
        </button>
      );
    } else if (answered) {
      return (
        <button
          className="bg-gray-500 hover:bg-gray-400 duration-700 rounded-lg px-5 py-1.5 text-white"
          onClick={nextFlashcard}
        >
          Next
        </button>
      );
    } else {
      return (
        <button
          className="bg-teal-800 hover:bg-teal-600 duration-700 rounded-lg px-5 py-1.5 text-white"
          onClick={answerFlashcard}
        >
          Answer
        </button>
      );
    }
  }

  useEffect(() => {
    if (sessionId) {
      fetch("https://flashcards.api.quanthium.io/session/" + sessionId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
        .then((res) => res.json())
        .then((data) => setFlashcards(data.flashcards));
    }
  }, [sessionId]);

  if (!flashcards) {
    return <div></div>;
  }

  return (
    <div className="App">
      <body className="text-center fixed inset-0 p-10 flex flex-col gap-5 item-center justify-center">
        {!finished && (
          <div>
            {position + 1} / {flashcards.length}
          </div>
        )}
        <div className="flex-1 flex flex-col items-center justify-center text-xl">
          <p
            dangerouslySetInnerHTML={{
              __html: getContent(),
            }}
          ></p>
        </div>
        <div>{getBottom()}</div>
      </body>
    </div>
  );
}

export default App;
